
export default () => {

	const filters = useState("filters", () => {
		const filters = localStorage?.getItem("filters")
		if (filters) {
			return JSON.parse(filters)
		} else {
			return {}
		}
	})

    function setProtocol (protocol) {
        filters.value.protocol = protocol
        localStorage.setItem("filters", JSON.stringify(filters.value))
    }

    async function setProtocolId (protocol_id) {
        const protocol = await useAPI(`protocol/${protocol_id}`)
        filters.value.protocol = protocol
        localStorage.setItem("filters", JSON.stringify(filters.value))
    }

    const hasFilters = computed(() => filters.value?.protocol)
    const protocol_id = computed(() => filters.value?.protocol?.id )
    const protocol = computed(() => filters.value?.protocol )

    return { filters, hasFilters, setProtocol, setProtocolId, protocol_id, protocol }
}